import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs text="Articles" to="/articles/" mdxType="Breadcrumbs" />
    <h2>{`Overview`}</h2>
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`See Also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/articles/backtesting/"
          }}>{`Backtesting Assumptions`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/charts-metrics/"
          }}>{`Our Charts and Metrics`}</a></li>
      </ul>
    </Aside>
    <p>{`We typically develop `}<em parentName="p">{`quantitative investment strategies`}</em>{` in a virtual environment. By simulating investment algorithms in so-called backtests, we can analyze the algorithm's behavior and performance over a historical period. However, it is not always clear if the assumptions made during backtesting hold in live trading. Therefore, it is crucial to verify that live trading tracks the simulated results closely. This article looks at two strategies we employ in our own accounts and compares the simulated results to our live trading.`}</p>
    <h2>{`Example 1: All-Stars Total Return`}</h2>
    <p>{`The first strategy we like to trade in our accounts is the `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-tr/"
      }}>{`All-Stars Total Return`}</a>{` portfolio. We consider this portfolio a `}<em parentName="p">{`middle-of-the-road`}</em>{` portfolio, delivering returns comparable to the `}<em parentName="p">{`S&P 500`}</em>{` while having a risk-profile closer to a `}<a parentName="p" {...{
        "href": "/portfolios/classic-60-40/"
      }}>{`60/40 Portfolio`}</a>{`. With these characteristics, the portfolio appeals to a wide range of investors.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/bf5a83101a0f1d6a58dbc87f5c44b194/21b4d/tt-all-stars-tr-v2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAChUlEQVQoz0VTW0tUURjdpmJaFNJP6K0H6bV6CPoB/YAozfsFUwiCCoWCMkTQyocSHxJEiuj+lIkWCCKKlnbm4qBjNdmMjjPjGT3n7DmXtWKfcXTD4tvfYu3FB9/aYjOVhq5n4XkeXdfFPuh5oOuBnkc6LgiPB8dBThW4ns3eiRjO94X4cHwdppWDcF2noANAeqAygurz8PwKOljY2MHQTBwXByIYnttg57tfENc0iCsaT99Zwa5hQdi2Dc8DbLfgkT9ZW3L2b5q1Y1He+rTOS4Mhlt/4SVGtUdRoFHVLFPUaS5uDEHUazt5fZXonqwwPJuTs7x1OrW3z5eIWq3oCrOhY2n+8TFEdYFFjkCXNhyhu8itErYaqe6tMZXQI0uGL+ThbX0d5oT/MkzeXKRryBqI+yOLmoG90pCnAIoXGAIVCQx7FyvC6xjPdEeyZEuL59AZLW9UUAZa1hFnRFmZl5wqPteXvR1vDrOwI80R7mGUtIV9T7mvCPN4eYklTEKUtIZ7rWcsbfgkl+Ww6ycHJFPu/xjkwsc2uDwmOzCc4PLPJkblNdn9MsHcizlfftzi2sOnzXe+TfDyZ5NRqGo/GE3wyuQWZy0EYOcnZWAbD8zHc/byKqgchXB6KIJrNYDz6D+FkFldHI+ibjiKUyiCTs/AjkcGp24uoGV3zN/lWS6DlTYSGsQfhuWq9IOj6mXs684exrOkvyabtZ3Bdz/oJsL3DMH6LJRjc1guJY0qa1Hd0CCklTdOEYVpwpETayGLXMKA4y5LYM/egGzqkJX3OtCwYhgk7ZyEnLZim5XOWacJxHLVlUmVRNbbtwHFc2I7j946br1JKKI3i1U8q3JXe9XV5To36H8OE2HpHpTSEAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader's All-Stars Total Return: live trading vs backtest",
            "title": "TuringTrader's All-Stars Total Return: live trading vs backtest",
            "src": "/static/bf5a83101a0f1d6a58dbc87f5c44b194/c1b63/tt-all-stars-tr-v2.png",
            "srcSet": ["/static/bf5a83101a0f1d6a58dbc87f5c44b194/5a46d/tt-all-stars-tr-v2.png 300w", "/static/bf5a83101a0f1d6a58dbc87f5c44b194/0a47e/tt-all-stars-tr-v2.png 600w", "/static/bf5a83101a0f1d6a58dbc87f5c44b194/c1b63/tt-all-stars-tr-v2.png 1200w", "/static/bf5a83101a0f1d6a58dbc87f5c44b194/21b4d/tt-all-stars-tr-v2.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The chart above shows how our live trading account closely tracks the portfolio's backtested results. However, a few details deserve mentioning.`}</p>
    <p>{`We are trading the strategy in an `}<em parentName="p">{`IRA account`}</em>{`, funded with approximately $120,000 on January 4th, 2021. We made no contributions to the account throughout the year and recorded its value daily. The account size is large enough to allow for accurate position sizing. Smaller accounts will most likely deviate more from their backtests.`}</p>
    <p>{`We are holding the account at `}<em parentName="p">{`Interactive Brokers`}</em>{`. The brokerage charges $0.015 commission per trade, with a minimum of $1. The `}<em parentName="p">{`All-Stars Total Return`}</em>{` portfolio trades frequently, and especially the `}<a parentName="p" {...{
        "href": "/portfolios/tt-vix-spritz/"
      }}>{`VIX Spritz`}</a>{` component makes daily adjustments. Because these adjustments are often minimal, we did not take every trade. Instead, we applied the following scheme:`}</p>
    <ul>
      <li parentName="ul">{`skip orders with a total dollar amount of less than $500`}</li>
      <li parentName="ul">{`skip orders changing one asset's position by less than 0.5%`}</li>
    </ul>
    <p>{`These rules help avoid the minimum commission, which effectively increases the commission paid per share. Therefore, this scheme is especially beneficial for smaller accounts. This step might not be necessary for very large accounts, but we still consider it good practice.`}</p>
    <p>{`We hold the portfolio in a `}<a parentName="p" {...{
        "href": "/help/managed-accounts/"
      }}>{`managed account`}</a>{`, which charges monthly management fees. Therefore, we adjusted our live trading results to their before-fees values to keep the results consistent with the backtested figures.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Live Trading`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Backtest`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Simulation Start: 01/04/2021`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$1,000.00`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$1,000.00`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Simulation End 12/10/2021`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$1,223.79`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$1,236.79`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Simulation Period`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.9 years`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Compound Annual Growth Rate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24.23%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`25.65%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Stdev of Returns (Monthly, Annualized)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`7.66%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6.83%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum Drawdown (Daily)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4.69%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4.79%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum Flat Days`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`46.00 days`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`43.00 days`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sharpe Ratio (Rf=T-Bill, Monthly, Annualized)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2.09`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2.31`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Beta (To Benchmark, Monthly)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.02`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- benchmark -`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Ulcer Index`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.35%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.41%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Ulcer Performance Index (Martin Ratio)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`17.93`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18.18`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The table above compares the backtested metrics with live traded results. We see that overall the results are very close. The remaining deviation is most likely caused by `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Slippage_(finance)"
      }}>{`slippage`}</a>{`. Because our portfolios use `}<a parentName="p" {...{
        "href": "https://www.sec.gov/fast-answers/answersmktordhtm.html"
      }}>{`market orders`}</a>{` on open, slippage may be slightly more pronounced than it would be using stop or limit orders. However, the results demonstrate that the price to pay for the convenience of placing orders outside of regular market hours is relatively small under typical market conditions.`}</p>
    <h2>{`Example 2: All-Stars Monthly`}</h2>
    <p>{`The second portfolio we trade is the `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-monthly/"
      }}>{`All-Stars Monthly`}</a>{` portfolio. The portfolio's objective is very similar to `}<em parentName="p">{`All-Stars Total Return`}</em>{`. However, less frequent trading thanks to the monthly rebalancing schedule helps implement the portfolio in retirement accounts with no margin capability.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5cc1677c8f6085d6d030a18f6aae8837/21b4d/tt-all-stars-monthly.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACk0lEQVQoz3VTSWgUQRQtN8QFoujFiwiCBwWXm+DNgxcR8SaIRI0alxBxwYPEJRd3jAYNShS9KN5cQVETJi4orpGe7pmYRUyiZqYzM0nPVK8170n1RMWDBY/3qPfrfYr6JTK5PEZHHZTLZUZRhEgpBmEEpcoE+J+lHW2W+dC0sbolzetv+1FyfQilorhElf8cH2MFMsKbgQK+FkoYcEoY9jx8yRXR1PGdqdwoO/rynFpnQaw3uKChC9INIIIwxO+QES9kMfLZ1pPnumtprmlNsWpvknMbDM5rMDn/SJKzD5gU1QZn7jc456BJUWNB1JhYcqyXhREHguUyB4pFfs4UeCbRz7VXuzhrn0mxwaDYlKTYalFsGcNmSwdwUm2FY73DgticxOLGXubjQAbceruXYksnp9UbFNU6xOTEWovjt1sct82k+A+0N6HWgm688PAXlKQH0ZwY5OSdKU7Zlea0XWlW1ac5fXeak3ekOb2usjej/l9of8Ye7ac4YbuFSbUpLj/eC9cLIB4ms2x9ZfNKosDmdptnn9i8mMjy5scMm57ZbHn5k433szx6L8ujd202PsjyQmKIh+/YcX17TwGnn2Z4qS0Hzw8gMo5ke98wrr7OYOOtNJadNHH59Xf0FIfR+KwPz/ttLD1lYNGJz5hzqBMrzht43J3B3COdWNncpccHNz78wKqWFKWU8aOgqHwO+jk+/TrEc88HOCyDeHC+uXmGCnz/M89PQyM07BEW/Ir3YjDLR93ZWIeI+C6TZclxIHw/oOMWIV0XCAPkSqNwpETJlXDcEqQrIV0HrufB81yoIICULsLAQxToPS/2Al9CKUWhO0RhBP1L9EyqSMVaQ6mK9jwfYRhVEGkOx/hvndb6/r8ANafLxRgGhk0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader's All-Stars Monthly: live trading vs backtest",
            "title": "TuringTrader's All-Stars Monthly: live trading vs backtest",
            "src": "/static/5cc1677c8f6085d6d030a18f6aae8837/c1b63/tt-all-stars-monthly.png",
            "srcSet": ["/static/5cc1677c8f6085d6d030a18f6aae8837/5a46d/tt-all-stars-monthly.png 300w", "/static/5cc1677c8f6085d6d030a18f6aae8837/0a47e/tt-all-stars-monthly.png 600w", "/static/5cc1677c8f6085d6d030a18f6aae8837/c1b63/tt-all-stars-monthly.png 1200w", "/static/5cc1677c8f6085d6d030a18f6aae8837/21b4d/tt-all-stars-monthly.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Again, we see that our live trading closely matches the backtested results. Here are some additional notes:`}</p>
    <p>{`We are trading the strategy in a `}<em parentName="p">{`Roth IRA`}</em>{` account with a starting value of approximately $100,000 on January 1st, 2021. This account size is significantly larger than the portfolio's minimum capital requirement, allowing for accurate position sizing.`}</p>
    <p>{`We hold the account at `}<em parentName="p">{`Interactive Brokers`}</em>{`, and it is subject to trading commissions identical to the previous example. Therefore, we use the same methods to reduce unnecessary trades outlined above to minimize the adverse effects of the minimum commission of $1 per trade.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Live Trading`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Backtest`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Simulation Start 01/04/2021`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$1,000.00`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$1,000.00`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Simulation End 12/10/2021`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$1,170.58`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$1,178.33`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Simulation Period`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.9 years`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Compound Annual Growth Rate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18.44%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`19.28%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Stdev of Returns (Monthly, Annualized)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6.81%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6.15%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum Drawdown (Daily)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3.49%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3.72%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum Flat Days`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`82.00 days`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`80.00 days`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sharpe Ratio (Rf=T-Bill, Monthly, Annualized)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.50`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.58`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Beta (To Benchmark, Monthly)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.03`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- benchmark -`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Ulcer Index`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.31%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.44%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Ulcer Performance Index (Martin Ratio)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14.09`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`13.34`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The table shows how the backtested results compare to live trading. Again, we see that live trading closely tracks the backtest with relatively minor deviations. Compared to the previous example, the tracking error is a bit smaller. This can probably be explained by the portfolio's monthly trading frequency, leaving less room for deviations.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`Based on these results, we are confident that our backtests closely match results from live trading. By applying some common-sense rules to filter unnecessary trades, we have been able to keep trading commissions low and avoid  excessive commissions caused by the broker's minimum commission amount.`}</p>
    <p>{`To better understand how we simulate our portfolios, we recommend interested investors to read about our backtesting assumptions.`}</p>
    <ButtonPrimary text="how we backtest" to="/articles/backtesting/" mdxType="ButtonPrimary" />
    <br /><br /><br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      